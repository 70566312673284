import Flickity from 'flickity'

function gallery() {
    document.querySelectorAll('.main-carousel').forEach((elem) => {
        var flkty = new Flickity( elem, {
            cellAlign: 'left',
            contain: true,
            prevNextButtons: false
          });
    })
}


export default gallery