import '../css/app.scss'

import gallery from './gallery.js'

const main = async () => {
  emojiCanvas()
  gallery()
}

document.addEventListener('DOMContentLoaded', (event) => {
  main().then(() => {
  })
})

let speed = 1000

function emojiCanvas () {
  const container = document.querySelector('.emoji-container')
  if (container) {
    generatEmoji()
    faster()
  }

  function generatEmoji () {
    const emoji = document.createElement('div')
    const prob = Math.floor((Math.random() * 14) + 1)
    emoji.innerHTML = '📍'
    if (prob > 6) emoji.innerHTML = '💞'
    if (prob > 7) emoji.innerHTML = '💞'
    if (prob > 10) emoji.innerHTML = '📍'
    if (prob > 12) emoji.innerHTML = '📏'
    emoji.style.left = Math.floor((Math.random() * (container.offsetWidth - 100)) + 1) + 'px'
    emoji.style.top = Math.floor((Math.random() * container.offsetHeight) + 1) + 'px'
    emoji.classList.add('emoji')
    container.appendChild(emoji)
    setTimeout(() => emoji.remove(), 5000)
    setTimeout(generatEmoji, speed)
  }

  function faster () {
    const us = document.querySelector('.int-us')

    us.addEventListener('mousedown', () => {
      speed = 100
    })

    us.addEventListener('mouseup', () => { 
      speed = 1500
    })
  }
}
